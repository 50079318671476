import React from "react";
import { Link } from "gatsby";
import picture from "../images/header-pic.jpeg";
import { withPrefix } from "gatsby";
export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  closeNavbar() {
    if (this.state.collapsed === false) {
      this.toggleNavbar();
    }
  }

  render() {
    return (
      <header className="mb-5">
        <nav className="navbar navbar-expand-md navbar-light pt-4">
          <div className="container">
            <Link className="navbar-brand" activeClassName="active" to="/">
              <img
                src={picture}
                width="45px"
                height="45px"
                className="header-pic"
                alt="profile"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={
                "collapse navbar-collapse " +
                (!this.state.collapsed ? "show mt-4" : "")
              }
              id="navbarCollapse"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/"
                    onClick={this.closeNavbar}
                  >
                    <i className="fas fa-map-marker-alt fa-lg mr-2" /> Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/about"
                    onClick={this.closeNavbar}
                  >
                    <i className="far fa-address-card fa-lg mr-2" /> About
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    activeClassName="active"
                    href={withPrefix("/resume/Matt-Williams-Resume-2018.pdf")}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.closeNavbar}
                  >
                    <i className="far fa-file-code fa-lg mr-2" /> Resume
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/contact"
                    onClick={this.closeNavbar}
                  >
                    <i className="far fa-envelope fa-lg mr-2" /> Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
