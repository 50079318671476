import React from "react";
import Helmet from "react-helmet";
import Transition from "../components/transition";
import Header from "../components/header";
import Footer from "../components/footer";
import "../styles/custom.scss";

const TemplateWrapper = ({ children, location }) => (
  <div className="body-layout">
    <Helmet title="Matt Williams">
      <meta
        name="description"
        content="Software developer Matt Williams' personal website."
      />
      <link
        rel="icon"
        type="image/x-icon"
        href="https://www.mattwilliams.io/favicon.ico"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
        integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Exo:300,500,800|Roboto"
        rel="stylesheet"
      />
    </Helmet>
    <Header />
    <Transition location={location}>{children}</Transition>
    <Footer />
  </div>
);

export default TemplateWrapper;
